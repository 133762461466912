<template>
  <div
    v-loading="loading"
    element-loading-text="数据较多，拼命加载中..."
    class="appealRecord-container"
  >
    <div class="hd">品牌方质检记录</div>
    <div class="common-screen-container">
      <div class="common-input-container">
        <span>品牌质检编号:</span>
        <el-input
          class="common-screen-input"
          v-model="params.qualityCode"
          placeholder="质检记录编号"
          clearable
        ></el-input>
      </div>
      <div class="common-input-container">
        <span>店铺名称:</span>
        <el-input
          class="common-screen-input"
          v-model="params.shopName"
          placeholder="店铺名称"
          clearable
        ></el-input>
      </div>
      <div class="common-input-container">
        <span>所属平台:</span>
        <el-select
          class="common-screen-input"
          v-model="params.platId"
          placeholder="所属平台"
          @change="commonFun"
          clearable
          filterable
        >
          <el-option
            v-for="item in platList"
            :key="item.id"
            :label="item.platformName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <br />
      <div class="common-input-container">
        <span>所属类目:</span>
        <el-select
          class="common-screen-input"
          v-model="params.categoryId"
          placeholder="所属类目"
          @change="commonFun"
          clearable
          filterable
          remote
          :remote-method="
            (val) => {
              remoteCategory(val);
            }
          "
          @focus="selectClear"
        >
          <el-option
            v-for="item in categoryList"
            :key="item.id"
            :label="item.platformName"
            :value="item.id"
          >
          </el-option>
          <div class="common-select-page">
            <div
              v-if="categoryParams.pageNum != 1"
              @click="
                () => {
                  selectPage('up');
                }
              "
            >
              上一页
            </div>
            <div v-if="categoryParams.pageNum == 1" style="color: #999">
              上一页
            </div>
            <div
              v-if="categoryList && categoryList.length == 10"
              @click="
                () => {
                  selectPage('down');
                }
              "
            >
              下一页
            </div>
            <div
              v-if="categoryList && categoryList.length < 10"
              style="color: #999"
            >
              下一页
            </div>
          </div>
        </el-select>
      </div>
      <div class="common-input-container">
        <span>问题大类:</span>
        <el-select
          class="common-screen-input"
          @change="(value) => dictFun(value, 'quality', 'quality2')"
          v-model="params.classificationQualityBigId"
          placeholder="请选择"
          clearable
          filterable
        >
          <el-option
            v-for="item in quality1"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </div>
      <div class="common-input-container">
        <span>问题点:</span>
        <el-select
          class="common-screen-input"
          @change="(value) => dictFun(value, 'quality', 'quality3')"
          v-model="params.classificationQualityPointId"
          placeholder="请选择"
          clearable
          filterable
        >
          <el-option
            v-for="item in quality2"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </div>
      <div class="common-input-container">
        <span>问题描述:</span>
        <el-select
          class="common-screen-input"
          @change="commonFun"
          v-model="params.classificationQualityDescId"
          placeholder="请选择"
          clearable
          filterable
        >
          <el-option
            v-for="item in quality3"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </div>
      <br />
      <div class="common-input-container">
        <common-date
          @commonGetDate="commonGetDate"
          ref="commonReset"
        ></common-date>
      </div>
      <div class="common-input-container">
        <el-button
          class="common-screen-btn"
          type="primary"
          @click="
            () => {
              qualityRecordList();
            }
          "
          >查 询</el-button
        >
        <el-button
          class="common-screen-btn clear"
          @click="
            () => {
              resetBtn();
            }
          "
          plain
          >重 置</el-button
        >
      </div>
    </div>
    <div class="common-grey-line"></div>

    <div class="common-padding">
      <el-table class="common-table" :data="tableData">
      <el-table-column width="130px" label="品牌质检编号">
        <template slot-scope="scope">
          <el-button
            @click="
              () => {
                detailBtn(scope.row);
              }
            "
            type="text"
            size="small"
            >{{ scope.row.qualityCode }}</el-button
          >
        </template>
      </el-table-column>
      <el-table-column
        width="160px"
        :formatter="tableColumn"
        prop="shopName"
        label="店铺名称"
      ></el-table-column>
      <el-table-column
        width="160px"
        :formatter="tableColumn"
        prop="customerAccount"
        label="顾客账号"
      ></el-table-column>
      <el-table-column
        :formatter="tableColumn"
        prop="platformName"
        label="所属平台"
      ></el-table-column>
      <el-table-column
        :formatter="tableColumn"
        prop="categoryName"
        label="所属类目"
      ></el-table-column>
      <el-table-column
        width="180px"
        :formatter="tableColumn"
        prop="qualityTime"
        label="反馈时间"
      ></el-table-column>
      <el-table-column
        width="180px"
        :formatter="tableColumn"
        prop="subaccountName"
        label="客服子账号"
      ></el-table-column>
      <el-table-column
        width="140px"
        prop="classificationQuality.bigList"
        label="问题大类"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        width="140px"
        prop="classificationQuality.pointList"
        label="问题点"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        width="180px"
        prop="classificationQuality.descList"
        label="问题描述"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        width="130px"
        :formatter="tableColumn"
        prop="remark"
        show-overflow-tooltip
        label="备注"
      ></el-table-column>
    </el-table>
    <el-pagination
      style="text-align: center"
      @size-change="(e) => pageFun(e, 'pageSize')"
      @current-change="(e) => pageFun(e, 'page')"
      :page-sizes="pagination.pageSizes"
      :page-size="params.pageSize"
      layout="total, prev, pager, next,sizes, jumper"
      :total="pagination.total"
    >
    </el-pagination>
    </div>
    <cus-quality
      ref="appealDetail"
      :drawer="drawer"
      :handleClose="
        () => {
          drawer = false;
        }
      "
    ></cus-quality>
  </div>
</template>
<script>
import CommonDate from "../../components/common/commonDate.vue";
import { tableColumn, Config } from "../../utils/index.js";
import cusQuality from "./children/cusQuality.vue";
import { qualityRecordList, getListByParentId } from "../../service/service.js";
import {
  commonDictSys,
  selectCategory,
  selectPlatform
} from "../../service/common.js";
import { getPlatList } from "../../service/customer.js";

export default {
  components: {
    cusQuality,
    CommonDate
    // aCascader: Cascader
  },
  name: "cusRecord",
  data() {
    return {
      tableColumn,
      rolesCompany: this.$sto.get(Config.constants.userInfo).company_id, // 所属公司
      drawer: false,
      loading: false,
      downLoading: false,
      BreadcrumbData: [
        { title: "首页", isLink: true, url: "/index" },
        { title: "质检", isLink: false },
        { title: "质检记录", isLink: false }
      ],
      tableData: [],
      tableRadio: "今天",
      pagination: {
        total: 0,
        pageSizes: [5, 10, 20, 50, 100]
      },
      params: {
        page: 1,
        pageSize: 10
      },
      platId: [],
      quality1: [],
      quality2: [],
      quality3: [],
      brand1: [],
      brand2: [],
      brand3: [],
      DictSysAll: { "7087cd552e9b4696bca2fcbb6261caae": [] },
      platList: [], // 所属平台
      categoryList: [], //类目下拉
      categoryParams: {
        pageNum: 1,
        pageSize: 10,
        name: ""
      }
    };
  },
  created() {
    if (this.rolesCompany) {
      this.params.companyId = this.rolesCompany;
    }

    // this.getPlatList()
    this.selectPlatform();
    this.selectCategory();
    this.getListByParentId("quality", 0, "quality1");
    this.getListByParentId("brand", 0, "brand1");
    this.qualityRecordList();
    this.commonDictSys("7087cd552e9b4696bca2fcbb6261caae");
  },
  computed: {},
  methods: {
    openHTML() {
      const that = this;
      this.$message({
        onClose: () => {
          that.downLoading = false;
        },
        dangerouslyUseHTMLString: true,
        showClose: true,
        duration: 1000,
        iconClass: "el-icon-warning-outline",
        customClass: "tip-alert",
        message:
          '<div class="tip-title"> <i class="iconfont icon-guanyu"></i> 导出提醒</div><div class="tip-info">请先选择导出时间段，否则导出数据内容过多，影响导出时间。</div>'
      });
    },
    download() {
      // 导出质检记录数据
      this.downLoading = true;
      let params = { ...this.params };
      if (!params.startDate || !params.endDate) {
        this.openHTML();
        return;
      }
      delete params.page;
      delete params.pageSize;
      console.log(params);
      let url = Config.api.url + "/quality/qualityRecord/qualityHistoryExport";
      let paramArr = [];
      for (let item in params) {
        if (params[item]) {
          paramArr.push(item + "=" + params[item]);
        }
      }
      url = url + "?" + paramArr.join("&");
      console.log(url);
      window.location.href = url;
      this.downLoading = false;
    },
    selectClear() {
      // 下拉清空时触发
      this.categoryParams.pageNum = 1;
      this.categoryParams.name = "";
      this.selectCategory();
    },
    selectPage(type) {
      // 下拉分页
      if (type == "up") {
        this.categoryParams.pageNum--;
      } else {
        this.categoryParams.pageNum++;
      }
      this.selectCategory();
    },
    remoteCategory(val) {
      // 所属类目下拉-筛选
      this.categoryParams.pageNum = 1;
      this.categoryParams.name = val;
      this.selectCategory();
    },
    async selectCategory() {
      // 类目下拉
      let categoryParams = { ...this.categoryParams };
      let { data } = await selectCategory(categoryParams);
      this.categoryList = data.list;
    },
    async selectPlatform() {
      // 平台下拉
      let { data } = await selectPlatform({ pageNum: -1 });
      this.platList = data;
    },
    async getPlatList() {
      // 所属平台/类目下拉
      let resData = (await getPlatList({ pageNum: -1 })).data;
      this.platList = resData;
    },
    dictFun(value, type, key) {
      // 问题筛选-重置下级菜单
      switch (key) {
        case "quality2":
          this.params.classificationQualityPointId = "";
          this.params.classificationQualityDescId = "";
          break;
        case "quality3":
          this.params.classificationQualityDescId = "";
          break;
        case "brand2":
          this.params.classificationBrandPointId = "";
          this.params.classificationBrandDescId = "";
          break;
        case "brand3":
          this.params.classificationBrandDescId = "";
          break;
      }
      this.getListByParentId(type, value, key);
      this.commonFun();
    },
    async getListByParentId(type, parentId, key) {
      // 问题下拉
      if (parentId || parentId == 0) {
        let resData = (await getListByParentId({ type, parentId })).data;
        this[key] = resData || [];
      }
    },
    commonFun() {
      // 下拉筛选
      this.params.page = 1;
      this.params.pageSize = 10;
      this.qualityRecordList();
    },
    async commonDictSys(uuid) {
      // 获取字典值接口
      let { data } = await commonDictSys({ uuid });
      this.DictSysAll[uuid] = data.list || [];
    },
    async qualityRecordList() {
      // 质检记录列表
      // let platId = [...this.platId];
      let params = { ...this.params };
      // if (platId && platId.length) {
      //     params.platformId = platId[0];
      //     if (platId[1]) {
      //         params.categoryId = platId[1];
      //     }
      // }
      //客户ID
      params.brandCustomerId = this.$sto.get(Config.constants.userInfo).user_id;
      this.loading = true;
      let { data } = await qualityRecordList(params);
      this.loading = false;
      this.pagination.total = data.total;
      this.tableData = data.list;
    },
    detailBtn(row) {
      // 查看详情按钮
      this.$refs.appealDetail.tableParams(row);
      this.drawer = true;
    },
    resetBtn() {
      // 重置
      this.$refs.commonReset.resetFun();
      this.params = {
        pageNum: 1,
        pageSize: 10,
        companyId: this.rolesCompany
      };
      this.qualityRecordList();
    },
    commonGetDate(startDate, endDate) {
      // 时间筛选
      this.params.startDate = startDate;
      this.params.endDate = endDate;
      this.qualityRecordList();
    },
    pageFun(e, type) {
      // 分页
      this.params[type] = e;
      this.qualityRecordList();
    }
  }
};
</script>
<style lang="less" scoped>
.appealRecord-container {
  text-align: left;
  .hd {
    height: 72px;
    line-height: 72px;
    padding-left: 32px;
    color: rgba(16, 16, 16, 100);
    font-size: 24px;
    border-bottom: 1px solid #e6e6e6;
    margin-bottom: 24px;
  }
  .common-screen-container {
    margin-left: 32px;
  }
  .line {
    height: 16px;
    background-color: #ededed;
  }
  .input-with-select {
    width: 180px;
    margin-left: 16px;
  }
  /deep/.input-with-select .el-input-group__prepend {
    background-color: #fff;
    padding: 0;
  }
  /deep/.input-with-select .el-input__inner {
    border-left: none;
    padding-right: 0;
    &:focus {
      border-color: #dcdfe6;
    }
  }
  .wran-input {
    width: 100px;
    border: none;
  }
  /deep/.wran-input .el-input-group__append {
    background-color: #fff;
    padding: 0;
    border: none;
    width: 20px;
  }
  /deep/.wran-input .el-input__inner {
    border: none;
    line-height: 30px;
    height: 30px;
    padding-right: 0;
    border-radius: 6px;
  }
}
</style>
<style lang="less">
.tip-alert {
  width: 616px;
  background: #e6f7ff;
  border: 1px solid #91d5ff;
  .el-icon-warning-outline {
    display: none !important;
  }
  .el-message__closeBtn {
    top: 25px;
  }
  .tip-title {
    color: #272424;
    font-size: 16px;
    font-weight: 600;
    line-height: 40px;
    display: flex;
    align-items: center;
    i {
      font-size: 32px;
      font-weight: 200;
      color: #1990ff;
      margin-right: 16px;
    }
  }
  .tip-info {
    text-indent: 48px;
    font-size: 14px;
    color: #5f5a59;
    padding-bottom: 22px;
  }
}
</style>
